<template>
    <v-row justify="center" style="background-color: #E1F5FE;" >
        <v-row justify="center" class="ma-15 pb-7">
          <v-col cols="12" md="12">
            <v-card            
            variant="outlined"
            style="border-color: #0D1B34;"
            >
              <v-card-text>
                <v-form @submit.prevent="subscribe">
                  <v-row>
                    <v-col cols="12" md="4">
                      <h2>{{$t('subscribe_to_newsletter')}}</h2>
                      <p>{{$t('receive_timely_news')}}</p>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="name"
                        clearable 
                        :label="`${$t('name')}`"
                         placeholder="Fill out your name"
                        prepend-inner-icon="mdi-account"
                        type="text"
                        variant="solo"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="email"
                        clearable 
                        :label="`${$t('email')}`"
                        placeholder="yourmail@example.com"
                        required
                        type="email"
                        prepend-inner-icon="mdi-email"
                        variant="solo"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                       <v-btn type="submit" color="#0D1B34" 
                        prepend-icon="mdi-email"
                        v-if="btnClicked" class="mt-3"
                      >{{$t('subscribe')}}</v-btn>
                      <v-btn type="submit" color="secondary" 
                        prepend-icon="mdi-email"
                         disabled v-else class="mt-3"
                      >Subscribing</v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
    </v-row>
</template>

<script>
export default {
  data() {
    return {
      btnClicked: 'false',
      name: '',
      email: '',
    };
  },
  methods: {
    subscribe() {
      // Add logic to handle subscription (e.g., send a request to your server)
      console.log('Subscribing with email:', this.email);

      // Optionally, reset the form after submission
      this.btnClicked = false;
      this.name = '';
      this.email = '';
    },
  },
};
</script>

  <template v-slot:default="{ isActive }">
                <v-card>
                  <v-toolbar
                    color="primary"
                    title="Opening from the top"
                  >
                    <v-btn
                      icon
                      dark
                      @click="isActive.value = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <div class="text-h2 pa-12">Hello world!</div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn
                        color="success"                        
                        @click="isActive.value = false"
                    >
                    Save Changes
                    </v-btn>
                    <v-btn
                      variant="text"
                      color="error"
                      @click="isActive.value = false"
                    >Close</v-btn>
                  </v-card-actions>
                </v-card>
              </template>


<script>

export default {
  name: 'MyModal',

  data: () => ({
    ecosystem: [
      
    ],

  }),
}
</script>

<template>
  <v-row  v-if="loading" class="justify-center mt-15">
    <!-- <v-progress-circular 
      indeterminate
      :size="70"
      :width="4"
      color="blue">
    </v-progress-circular> -->
    <v-col cols="12" md="12" sm="11" xs="12" class="pa-5">
      <v-skeleton-loader :elevation="0" type="card"></v-skeleton-loader>
    </v-col>
  </v-row>
  <v-row  v-else style="  margin-top: -25px;">
    <v-col cols="12" md="12" sm="11" xs="12">
      <v-carousel v-model="carouselModel"
        :cycle="cycle"       
        progress="white" touch="true" color="blue" height="400"> <!-- interval="3000" -->
        <v-carousel-item v-for="(item, i) in slidesResponse" :key="i" 
          transition="slide-x-transition"
          :src="`${$root.globalImgUrl}${item.image}`" 
          :lazy-src="`${$root.globalImgUrl}${item.image}`" cover>  
            <v-row  class="fill-height align-center justify-center text-center mt-35">
              <v-col cols="9" sm="8" md="6" style="margin-top: 180px;" >
                <v-card style="opacity:0.6; margin-top: 40px" min-height="100" class="fill-height d-flex align-center justify-center mt-35">
                  <v-text  class="text-h4">
                    {{ item.name }} 
                  </v-text>
                </v-card>
              </v-col> 
            </v-row >
        </v-carousel-item>
      </v-carousel>
    </v-col>
    <!-- <v-col cols="12" md="3" sm="11" xs="12">
      <v-carousel cycle v-model="carouselModel" 
        interval="3000" height="400" :show-arrows="false" hide-delimiters>
      <v-carousel-item v-for="(item, index) in slidesResponse" :key="index"
        color="blue-lighten-5"  aria-label="Item 1" style="background-color: ">
        <v-carousel-item-text style="opacity:0.7;" class="fill-height align-center justify-center text-center">
          <v-row  class="fill-height align-center justify-center text-center ">
              <v-col cols="12"  md="11" sm="11" xs="12" class="align-center">
                <v-expand-transition>
                <v-card style="opacity:0.9; background-color: white;" min-height="100" class="fill-height d-flex align-center justify-center pa-3" elevation="0">
                  <v-text  class="text-h5">
                      {{ item.name }} 
                    </v-text>
                </v-card>
                <v-card style="opacity:0.9; background-color: white;" min-height="50" class="fill-height  align-center justify-center pa-3"  elevation="0">
                  <v-text  >
                      {{ item.desc }} 
                    </v-text>
                </v-card>
                </v-expand-transition>
              </v-col> 
            </v-row > 
        </v-carousel-item-text>
      </v-carousel-item>
    </v-carousel>
    </v-col> -->
  </v-row>
</template>
<script>
import axios from 'axios';
export default {
  name: 'Slider',
  data: () => ({
    slidesResponse: [],
    carouselModel: 0,
    cycle: true, // Whether the carousel should cycle automatically
    autoplayIntervalId: null, // Interval ID for autoplay
    loading: true,
    transitions: ['slide-y-transition', 'slide-x-transition', 'fab-transition', 'fade-transition', 'scale-transition', 'slide-x-reverse-transition', 'slide-y-reverse-transition'],
  }),

  mounted() {
    this.getSlides(); 
  },
  /*beforeDestroy() {
    // Clear autoplay interval when component is destroyed
    clearInterval(this.autoplayIntervalId);
  },*/
  computed: {
    
  },
  methods: {
    getTransition() {
      // Get the current language array based on the index
      const randomIndex = Math.floor(Math.random() * this.transitions.length);
      return this.transitions[randomIndex];
    },
    async getSlides(){
      await axios.get('/slides')
        .then(response => {
          this.slidesResponse = response.data.mydata
          // console.log(this.slidesResponse);
          this.loading = false
        })
        .catch(error => {
          console.log(error)
        });
    },
  }
};
</script>


<style scoped>
.fill-height {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
</style>
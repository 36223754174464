<template>
  <v-sheet  :class="$root.mobile ? 'ml-4 mr-4 ' : 'ml-10 mr-10 '">
    <v-container fluid>
    <v-row class="justify-center mb-7 mt-5 ">
          <span class="text-h4 my-text-color" style="border-bottom:5px dashed #0D1B34;">{{$t('Staylatestupdates')}}</span>          
      </v-row>

      <v-row v-if="loading">
        <v-col cols="12" md="4" 
        v-for="i in 3" :key="i">
          <v-skeleton-loader
            class="mx-auto border"
            max-width="300"
            type="image, article"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
          <v-hover
            v-for="post in postsResponse"
            :key="post"            
            v-slot="{ isHovering, props }"
            open-delay="200"
          >
            <v-col cols="12" md="4" sm="6" xs="12">
              <v-card
                :elevation="isHovering ? 16 : 1"
                :class="{ 'on-hover': isHovering }"
                
                class="ma-3 mx-auto "
                
                min-height="400"
                width="100%"
                @click="toggle"
                v-bind="props"
              >
                <a :href="`/#/posts/${getCatSlug(post.category_id)}/${post.slug}`" style="text-decoration: none;">
                <v-img
                  :src="`${$root.globalImgUrl}${post.image}`" 
                  :lazy-src="`https://picsum.photos/10/6?image=${1 * 5 + 10}`"
                  class="align-end"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  cover 
                  height="200"            
                >
                  <v-card-title class=" text-white " style="background-color: #0D1B34" >
                    <!-- <div v-html="post.title"></div> -->
                    <div v-html="trans(post.id, post, 'title')"></div>
                  </v-card-title>
                </v-img>
                </a>
                <v-card-text>
                  <v-icon size="17"> mdi-calendar </v-icon> {{ (post.created_at).split('T')[0] }}
                  <div v-html="removeHtmlFormatting(trans(post.id, post, 'body'), 110)"></div>
                </v-card-text>
                <v-card-actions>
                  <v-btn size="small" :to="`/posts/${getCatSlug(post.category_id)}/${post.slug}`" color="#0D1B34" variant="text" prepend-icon="mdi-more">Read More</v-btn>
                  <v-spacer></v-spacer>

                  <!-- Facebook Share Button -->
                  <v-btn @click="shareOnFacebook(post.slug)" icon="" color="indigo">
                    <v-icon left>mdi-facebook</v-icon>
                  </v-btn>

                  <!-- Twitter Share Button -->
                  <v-btn @click="shareOnTwitter(post.slug)" icon="" color="light-blue">
                    <v-icon left>mdi-twitter</v-icon>
                  </v-btn>

                  <!-- WhatsApp Share Button -->
                  <v-btn @click="shareOnWhatsApp(post.slug)" icon="" color="green">
                    <v-icon left>mdi-whatsapp</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-hover>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
import axios from 'axios';
export default {
  name: 'updates',
  data: () => ({
    postsResponse: [],
    categoriesResponse: [],
    loading: true,
    originalText: '',
    catslug: null,
  }),
  mounted () {   
      this.activeLng = this.$i18n.locale;
      this.getCategories();
      this.getPosts();
    },
  computed: {
    sanitizedText() {
      return this.removeHtmlFormatting(this.originalText);
    },
  },
  methods: {
    // itemIsInteresting(body){
    //   this.originalText = body;
    // },
    lang(lng){
      this.activeLng = lng;
    },
    trans(id, str, col){

      const translation = str.translations.find(
        translation => translation.locale === this.$root.activeLng &&
        translation.column_name === col
      );
      if(col == 'title')
        return translation ? translation.value : str.title;
      else if(col == 'body')
        return translation ? translation.value : str.body;
    },
    removeHtmlFormatting(text, maxLength) {
      // Create a temporary div element
      const tempDiv = document.createElement('div');

      // Set the innerHTML of the div to the provided text
      tempDiv.innerHTML = text;

      // Retrieve the text content from the div (without HTML formatting)
      // return tempDiv.textContent.trim() || tempDiv.innerText.trim();

      if (text.length > maxLength) {
        // shortenText
        return (tempDiv.textContent.substring(0, maxLength) + '...') || (tempDiv.innerTextsubstring(0, maxLength) + '...');
      }
      return tempDiv.textContent.trim() || tempDiv.innerText.trim();
    },

    shortenText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      }
      return text;
    },

    async getPosts(){
      await axios.get('/posts')
        .then(response => {
          this.postsResponse = response.data.mydata.slice(0, 6);
        })
        .catch(error => {
          console.log(error)
        });
    },
    async getCategories(){
      await axios.get('/categories')
        .then(response => {
          this.categoriesResponse = response.data.mydata;
          this.loading = false;
        })
        .catch(error => {
          console.error('Error fetching about data:', error);
        });
    },

    getCatSlug(c_id){
      let slug = this.categoriesResponse.filter(item => item.id === parseInt(c_id));
      return slug[0].slug;
    },

    shareOnFacebook(slug) {
      const shareUrl = 'https://www.tigdigital.gov.et/index/#/posts/news/'+slug; // Replace with your news URL
      const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
      window.open(facebookUrl, '_blank');
    },

    shareOnTwitter(slug) {
      const shareText = 'Check out this news!'; // Replace with your news content
      const shareUrl = 'https://www.tigdigital.gov.et/index/#/posts/news'+slug; // Replace with your news URL
      const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareUrl)}`;
      window.open(twitterUrl, '_blank');
    },

    shareOnWhatsApp(slug) {
      const shareText = 'Check out this news!'; // Replace with your news content
      const shareUrl = 'https://www.tigdigital.gov.et/index/#/posts/news'+slug; // Replace with your news URL
      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(`${shareText} ${shareUrl}`)}`;
      window.open(whatsappUrl, '_blank');
    },

  }
};
</script>
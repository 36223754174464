<script>
import { defineComponent } from 'vue';

import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: 'RolesIndex',
    components: {
    },
    data () {
        return { 
            rolesResponse: [],
            loading: true,
            alert: false,
        }
    },
    mounted () {
      this.getRoles();
    },
    methods: {
        deleteFun(id){
            let result = confirm("I am asked to delete the row!");
            if(result){
              axios.delete('/roles/'+id)
              .then(function (response) {
                console.log(response);
                // alert("Row deleted successfuly"+id);                
              })
              .catch(function (error) {
                console.log(error);
              });
              this.getRoles();
            }              
            else 
              {
                alert("Delete operation canceled");
              }
          },
        getRoles(){
          axios
            .get('/roles')
              .then(response => {
                this.rolesResponse = response.data.roles
                this.loading = false
              })
              .catch(error => {
                console.log(error)
              });
        },
    },
    setup() {
        const name = ref('');
        const email = ref('');
        const password = ref('');
        const password_confirmation = ref('');
        const router = useRouter();
        const validation = ref([]);

        const submit = async () => {
          await axios
            .post('/register', {
              name: name.value,
              email: email.value,
              password: password.value,
              password_confirmation: password_confirmation.value,
            })
            .then(() => {
                this.alert = true;
              // router.push({ path: '/login' });
            })
            .catch((error) => {
              validation.value = error.response.data;
            });
        };

        return {
          name,
          email,
          password,
          password_confirmation,
          submit,
          validation,
        };
    },
});
</script>

<template>
  
  <v-card  class="mx-auto" 
      color="grey-lighten-3"
      prepend-icon="mdi-account-group-outline"
      >
      <template v-slot:title>
        
        <v-row >
          <v-col cols="10">Roles</v-col>
          <v-col cols="2" class="">
            <v-btn 
            color="success" prepend-icon="mdi-plus" 
            size="small" class="mt-2 pull-right"
            to="/roles/create"
            >Add Role</v-btn>
          </v-col>
        </v-row >
      </template>
    <v-card-text>
      <v-layout>

        <v-main>
          <v-container fluid>
          
            <v-row v-if="loading" >
              <v-col cols="12"  >
              <v-card  >
            <div class="text-xs-center">
                <v-progress-circular
                  indeterminate
                  :size="50"
                  :width="8"
                  color="green">
                </v-progress-circular>
              </div>
            </v-card>
            </v-col>
            </v-row>
          <v-row v-else fluid>
          <v-col cols="12">
          <v-card>
            <v-table grid-list-xl
              fixed-header
              height="300px"
            >
              <thead>
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Name</th>
                  <th class="text-left"># of Users</th>
                  <th class="text-left">Created At</th>
                  <th class="text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in rolesResponse"
                  :key="index"
                >
                  <td>{{ index+1 }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.users_count }}</td>
                  <td>
                    {{ (item.created_at).split('T')[0] }}
                  </td>
                  <td>
                    <v-row v-if="index > 0">
                       <v-btn
                            color="green"  size="x-small"  
                            prepend-icon="mdi-pencil" 
                            v-bind="props"
                            :to="`/roles/${item.id}/edit`"
                          >
                            Edit
                          </v-btn>
                      &nbsp;
                      <v-btn 
                      color="error"  size="x-small"  
                      prepend-icon="mdi-delete"                
                      @click="deleteFun(item.id)">Delete</v-btn>
                    </v-row>                  
                  </td>
                </tr>
              </tbody>
            </v-table>
          </v-card>
          </v-col>
          </v-row>
        </v-container>
          
        </v-main>
      </v-layout>
    </v-card-text>
  </v-card>
</template>
<template>
  <!-- <v-row class="text-center justify-center">
    <v-row justify="center"> -->
      <v-dialog
        v-model="dialog"
        persistent
        scrollable
        transition="dialog-bottom-transition"
        max-width="800"
      >
        <template v-slot:activator="{ props }">
          <!-- <v-btn
            color="primary"
            v-bind="props"
          >
            Open Dialog
          </v-btn> -->
          <v-btn icon color="blue" >
            <v-badge :content="`${displayLimit}+`" color="error" v-bind="props">
              <v-icon>mdi-bell-outline</v-icon>
            </v-badge>
          </v-btn>
        </template>
          <v-card>
            <v-card-title class="text-h5">
              <v-row class="ma-2">
              Up coming calendars
              <v-spacer></v-spacer>
              <v-btn
                color="blue-darken-1"
                variant="tonal"
                to="/calendars"
                prepend-icon="mdi-calendar"
                @click="dialog = false"
              >
                Calendars
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="red-darken-1"
                variant="tonal"
                @click="dialog = false"
                icon
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              </v-row>
            </v-card-title>
            <v-card-text>
              <center v-if="loading">
                <v-progress-circular
                  color="blue"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-circular>
              </center>
              <div 
                v-else
                v-for="calendar in displayedCalendars" 
                :key="calendar.id"
              >
                
                <v-alert
                  class="text-justify"
                  v-model="alert"
                  border="start"
                  icon="mdi-calendar"
                  color="blue-darken-1"
                  :title="`${calendar.name}`"
                >
                  <div v-html="calendar.description"></div>
                  Aenean imperdiet. Quisque id odio. Cras dapibus. Pellentesque ut neque. Cras dapibus. Curabitur ligula sapien, tincidunt non, euismod vitae.
                </v-alert>

                <br>
              </div>

              
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green-darken-1"
                variant="tonal"
                @click="dialog = false"
              >
                Disagree
              </v-btn>
              <v-btn
                color="green-darken-1"
                variant="tonal"
                @click="dialog = false"
              >
                Agree
              </v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
    <!-- </v-row>
  </v-row> -->
</template>

<script>
import axios from 'axios';

  export default {  
  data () {
    return {
      alert: true,
      dialog: false,
      calendarsResponse: [],
      loading: true,
      displayLimit: 3, // Set your desired display limit
    }
  },

  mounted () {          
    this.getCalendars();
  },

  computed: {
    displayedCalendars() {
      // Use slice to limit the displayed items
      return this.calendarsResponse.slice(0, this.displayLimit);
    },
  },

  methods: {

    async getCalendars(){
      await axios.get('/calendars')
        .then(response => {
          this.calendarsResponse = response.data.mydata;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
        });
    },

  }

}
</script>
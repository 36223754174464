<script>
import { defineComponent } from 'vue';

// Components
import MyModal from '@/views/settings/MyModal.vue';

export default defineComponent({
  name: 'Settings',

  components: {
    MyModal,
  },
});

</script>
<template>
  
  <v-card  class="mx-auto" color="grey-lighten-3">
    <v-layout>
        <v-container fluid>
          <div class="about">
              <h2>Settings</h2>
            </div>
          <v-row dense>
            
            <v-dialog
              transition="dialog-top-transition"
              width="auto" 
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  color="primary"
                  v-bind="props"
                >From the top</v-btn>
              </template>
              <MyModal/>
            </v-dialog>

            <v-col v-for="n in 4" :key="n" cols="12">
              <v-card
                :title="`Content ${n}`"
                :subtitle="`Subtitle for Content ${n}`"
                text="Lorem ipsum dolor sit amet consectetur, adipisicing elit.?"
              ></v-card>
            </v-col>
            <v-col cols="12" md="6">
            
          </v-col>

          </v-row>
        </v-container>
    </v-layout>
  </v-card>
</template>

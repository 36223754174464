import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
import router from './router'
import store from './store/auth'

import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

import axios from 'axios'

import i18n from './locales/i18n';

// axios.defaults.baseURL = 'http://localhost:8000/api/';
axios.defaults.baseURL = 'https://www.tigdigital.gov.et/adt/public/api/';

axios.defaults.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
axios.defaults.headers['Content-type'] = 'multipart/form-data';

loadFonts()


const app = createApp(App)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)
app.use(store)
// app.use(VueSession)
app.use(router)
app.use(vuetify)
app.use(i18n)
app.mount('#app')

// // ✅ works because the pinia instance is now active
// const userStore = useUserStore()
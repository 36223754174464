<template>
	<v-sheet style="background-color:#f8f8f8">
		<v-row class="ma-5 pb-0">
			<v-col cols="12" sm="8" md="12" offset="0"
				v-for="(trend, index) in trendsResponse"
				:key="index"
			>				
				<v-card class="pa-5 " elevation="0">
					<v-row class="justify-center pa-5">
					<v-row v-if="index % 2 === 0">
						<v-col cols="12" sm="8" md="6" 
							>
							<v-alert
								border="top"
								border-color="#0D1B34"
								elevation="0"
								icon="mdi-firework"
								density="compact"
								>
								<strong>{{ trend.title }}</strong>

							</v-alert>
							<v-row v-if="trend.subtitle" class=" pa-5 pb-3">
								{{ trend.subtitle }} 
							</v-row>
							<v-row class=" pa-5 pt-4">
								<div v-html="trend.content"></div>
							</v-row>
						</v-col>
						<v-col cols="12" sm="8" md="6" 
							>
							<v-card>
				              <v-img
				              	v-if="trend.media_type == 'Photo'"
				                :src="`${$root.globalImgUrl}${trend.image}`" 
				                class="align-end"
				                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)"
				                max-height="400px"
				                fit
				              >
				              </v-img>
				              <iframe v-else width="100%" height="400" :src="trend.video" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
				          	</v-card>
						</v-col>						
					</v-row>
					<v-row v-else>
						<v-col cols="12" sm="8" md="6">
							<v-card>
				              <v-img
				              	v-if="trend.media_type == 'Photo'"
				                :src="`${$root.globalImgUrl}${trend.image}`" 
				                class="align-end"
				                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)"
				                max-height="400px"
				                fit
				              >
				              </v-img>
				              <iframe v-else width="100%" height="400" :src="trend.video" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
				          	</v-card>
						</v-col>
						<v-col cols="12" sm="8" md="6"
							>
							<v-alert
								border="top"
								border-color="#0D1B34"
								elevation="0"
								icon="mdi-firework"
								density="compact"
								>
								<strong>{{ trend.title }}</strong>

							</v-alert>
							<v-row class="justify-center pa-5">
								{{ trend.subtitle }} <br>
							</v-row>
							<v-row class="justify-center pa-5">
								<div v-html="trend.content"></div>
							</v-row>
						</v-col>
					</v-row>
						
					</v-row>
				</v-card>
			</v-col>
		</v-row>
	</v-sheet>
</template>



<script>
import axios from 'axios';

export default {
  name: 'Services',
  components: {
    // slider,
  },
  data: () => ({
    trendsResponse: [],

    cards: [
      { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 12 },
      { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 12 },
      { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 12 },
    ],

  }),
  mounted () {         
      this.getServices();

    },

  methods: {    

    getServices(){
      axios
      .get('/trends')
        .then(response => {
          this.trendsResponse = response.data.mydata
          // this.loading = false
          console.log(this.trendsResponse)
        })
        .catch(error => {
          console.log(error)
        });
    },

  }
}
</script>

<style scoped>
.even {
  background-color: lightblue;
  float: right;
}

.odd {
  background-color: lightcoral;
  float: left;
}
</style>
<script>
import { defineComponent } from 'vue';
import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

export default {
  name: 'RoleEdit',
  
  data: () => ({
      valid: true,
      permissions: [],
      permissionsResponse: [],
      role: [],
      router: useRouter(),
      
      model: {
        role: {
          roleName: '',          
        }
      },
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
    }),

    computed: {
      currentRouteName(){
        return this.$route.name;
      }
    },
    mounted () {
      axios
      .get('/permissions')
        .then(response => {
          this.permissionsResponse = response.data.permissions
          this.loading = false
        })
        .catch(error => {
          console.log(error)
        });
      axios
      .get('/roles/'+this.$route.params.id)
        .then(response => {
          this.model.role.roleName = response.data.role.name;
          // this.permissions = response.data.role.permissions;
          // for ((item, index) in response.data.role.permissions) {
          //   this.permissions[index] = item.name;
          // }
          for (let i = 0; i < (response.data.role.permissions).length; i++) {
           // console.log(`Index ${i}: ${response.data.role.permissions[i].name}`);
           this.permissions.push(response.data.role.permissions[i].name);
          }
          console.log(this.permissions);
          this.loading = false;
        })
        .catch(error => {
          console.log(error)
        });
    },

    methods: {
      async submit(){
          let data = {roleName: this.model.role.roleName, permissions: this.permissions};
          console.log(data);
          axios.put('/roles/'+this.$route.params.id, data)
          .then(function (response) {
            console.log(response);
             
          })
          .catch(function (error) {
            console.log(error);
          });
          await this.router.push({ path: '/roles' });
        },
      async validate () {
        const { valid } = await this.$refs.form.validate()

        if (valid) {
          //alert('Form is valid')
          this.submit();
        }
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },
};
</script>

<template>
  
  <v-card  class="mx-auto" color="grey-lighten-3">
    <v-layout>

      <v-main>
        <v-container fluid>
            
            <v-card prepend-icon="mdi-account-group-outline">
               <template v-slot:title>        
                <v-row >
                  <v-col cols="10">Create Role</v-col>
                  <v-col cols="2" class="">
                    <v-btn 
                    color="success" prepend-icon="mdi-arrow-left" 
                    size="small" class="mt-2 pull-right"
                    to="/roles"
                    >Roles List </v-btn>
                  </v-col>
                </v-row >
              </template>
            <v-row>
              <v-col cols="12">
                  
                <v-form v-model="valid" ref="form">
                    <v-container>
                    <v-row>
                        <v-col cols="12" md="3">
                        <v-text-field
                            v-model="model.role.roleName"
                            :rules="nameRules"
                            :counter="10"
                            label="Role Name *"
                            required
                        ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-btn
                              color="success"
                              class="mt-4"
                              @click="validate"
                            >
                            Save Changes
                          </v-btn> 
                        </v-col>
 
                        <v-col cols="12" md="12"><p>{{ permissions }}  </p></v-col>
                        <v-container fluid><v-row>
                          <v-col cols="12" v-for="(item, index) in permissionsResponse"
                              :key="index" sm="4" md="3">
                            <v-checkbox
                              v-model="permissions"
                              :label="`${item.name}`"
                              :value="`${item.name}`"
                            ></v-checkbox>

                            <!-- <v-switch
                              v-model="permissions"
                              color="primary"
                              :label="`${item.name}`"
                              :value="`${item.name}`"
                              hide-details
                            ></v-switch> -->
                          </v-col></v-row>
                        </v-container>
                        
                        <v-col cols="12" md="12">
                          <v-btn
                                color="success"
                                class="mt-4"
                                @click="validate"
                            >
                            Save Changes
                            </v-btn> &nbsp;
                            <v-btn
                                color="error"
                                class="mt-4"
                                type="reset"
                                >
                                Reset
                            </v-btn>
                        </v-col>
                    </v-row>
                    </v-container>
                </v-form>
                  
              </v-col>
            </v-row>
          </v-card>
        </v-container>   
      </v-main>
    </v-layout>
  </v-card>
</template>

import { createStore } from 'vuex'
import axios from "axios";

import Vue from "vue";
// import Vuex from "vuex";
// import Axios from "axios";
// import CartModule from "./cart";
// import OrdersModule from "./orders";
// import AuthModule from "./auth";

const baseUrl = "http://localhost:8081/api";
const productsUrl = `${baseUrl}/products`;
const categoriesUrl = `${baseUrl}/categories`;


export default createStore({
  strict: true,
  state: {
    users: [],
  },
  getters: {
    getUsers: (state) => state.users,
  },
  mutations: {
    SET_USERS(state, users) {
      state.users = users;
    },
  },
  actions: {
    async fetchUsers({ commit }) {
      try {
        const data = await axios.get(
          "http://localhost:8081/api/users"
          // "https://jsonplaceholder.typicode.com/users"
        );
        commit("SET_USERS", data.data.users);
        // commit("SET_USERS", data.data);
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
  },
  modules: {
    // cart: CartModule, 
    // orders: OrdersModule, 
    // auth: AuthModule
  }
}) 

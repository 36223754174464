<template>
  <Index/>
</template>
<script>
import { defineComponent } from 'vue';

// Components
import Index from './Index.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    Index,
  },

  methods: {
     
  },
});
</script>
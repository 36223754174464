// stores/users.js
import { defineStore } from 'pinia'
// Import axios to make HTTP requests
import axios from "axios"
import { useRouter } from 'vue-router';

export const useUserStore = defineStore("user",{
    state: () => ({
        users: [],
        user: {},
        router : useRouter(),
    }),
    getters: {
        getUsers(state){
            return state.users
        },
        getUser(state){
            return state.user
        }
    },
    actions: {
        async fetchUser() {
            const res = await fetch("http://localhost:8081/api/user");
      
            const user = await res.json();
            this.user = user;
          },

          async signUp(email, password) {
            const res = await fetch("http://localhost:8081/api/register", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ email, password }),
            });
            const user = await res.json()
            this.user = user;
          },

          async signIn(email, password) {
            try {                
                /*const response =   axios.post("/login", {
                    email: email,
                    password: password
                  }, {
                    headers: {'Content-Type': 'application/json'}
                  }).catch(function(error) {
                    console.log(error);
                  });*/

                axios.post('/login', {
                    email: email,
                    password: password
                    }, {
                    headers: {'Content-Type': 'application/json'}
                  })
                  .then(response => {
                    this.user = response.data.user;
                    // console.log(this.user);
                    alert(this.user.name);
                    localStorage.setItem('token', response.data.accessToken);
                    // this.router.push({ path: '/dashboard' });
                  })
                  .catch(error => {
                    console.log(error)
                  });
                // const res = response;
                // if (!res.ok) {
                //     throw new Error(`Error! status: ${res.status}`);
                // }
                
                // this.user  = response.data.user;
                // console.log(`User: ${this.user}`);
                
            } catch (err) {
            // console.log(err);
          }
          },
        //
        async fetchUsers() {
            try {
              const data = await axios.get(
                'http://localhost:8081/api/users'
                // 'https://jsonplaceholder.typicode.com/users'
                )
                this.users = data.data.users
                // this.users = data.data
              }
              catch (error) {
                alert(error)
                console.log(error)
            }
        }
    },
})
<script>
// import Navbar from '@/components/Navbar.vue';
import { defineComponent } from 'vue';

import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: 'Register',
    components: {
    // Navbar,
    },
    data () {
        return { 
          name: '',
          role: {name: 'Select Role', id: ''},
          email: '',
          password: '',
          password_confirmation: '',
          usersResponse: [],
          rolesResponse: [],
          loading: true,
          alert: false,
          router: useRouter(),
          // validation: ref([]),
          
        }
    },
    mounted () {
      axios.get('/users')
        .then(response => {
          this.usersResponse = response.data.users
          this.loading = false
        })
        .catch(error => {
          console.log(error)
        });
      axios.get('/roles')
        .then(response => {
          this.rolesResponse = response.data.roles
          this.loading = false
        })
        .catch(error => {
          console.log(error)
        });
    },
    methods: {
      async  submit(){
          /*let data = {
              name: this.name,
              roleID: this.role.id,
              email: this.email,
              password: this.password,
              password_confirmation: this.password_confirmation,
            };
            console.log(data);*/
          await axios
            .post('/register', {
              name: this.name,
              roleID: this.role.id,
              email: this.email,
              password: this.password,
              password_confirmation: this.password_confirmation,
            })
            .then(() => {
                this.alert = true;
              router.push({ path: '/members' });
            })
            .catch((error) => {
              // validation.value = error.response.data;
            });
        },
        deleteFun(id){
            let result = confirm("I am asked to delete the row!");
            if(result){
              axios.delete('/user/'+id)
              .then(function (response) {
                console.log(response);
                alert("Row deleted successfuly"+id);
              })
              .catch(function (error) {
                console.log(error);
              });
            }              
            else 
              {
                alert("Delete operation canceled");
              }
          },
    },
});
</script>

<template>
  
  <v-card  class="mx-auto" color="grey-lighten-3">
    <v-layout>

      <v-main>
        <v-container fluid>
            <div class="dashboard">
              <h2>Users</h2>
            </div>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-form v-model="valid" ref="form" @submit.prevent="submit">
                            <v-container>
                            <v-row>

                                <v-col
                                cols="12"
                                md="4"
                                >
                                <v-text-field
                                    v-model="name"
                                    label="Full Name"
                                    required
                                ></v-text-field>
                                </v-col>
                                <v-col
                                cols="12"
                                md="4"
                                >
                                <v-text-field
                                    v-model="email"
                                    label="E-mail"
                                    required
                                ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-select
                                      label="Select Role"
                                      v-model="role"
                                      :hint="`${role.name} ${role.id}`"
                                      :items="rolesResponse"
                                      item-title="name"
                                      item-value="id"
                                      persistent-hint
                                      return-object
                                      single-line
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        v-model="password"
                                        name="password"
                                        type="password"
                                        label="Password"
                                        required
                                    ></v-text-field>
                                </v-col>                                
                                <v-col cols="12" md="4">
                                  <v-text-field
                                        v-model="password_confirmation"
                                        name="password_confirmation"
                                        type="password"
                                        label="Password Confirmation"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12">
                                  <v-btn
                                        color="success"
                                        class="mt-4"
                                        type="submit"
                                        @click="submit"
                                    >
                                    Submit
                                    </v-btn> &nbsp;
                                    <v-btn
                                        color="success"
                                        class="mt-4"
                                        @click="validate"
                                    >
                                    Validate
                                    </v-btn> &nbsp;
                                    <v-btn
                                        color="error"
                                        class="mt-4"
                                        @click="reset"
                                        >
                                        Reset Form
                                    </v-btn>
                                    &nbsp;
                                    <v-btn
                                        color="warning"
                                        class="mt-4"
                                        @click="resetValidation"
                                        >
                                        Reset Validation
                                    </v-btn>
                                </v-col>
                            </v-row>
                            </v-container>
                        </v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-alert 
                                      v-if="alert"
                                      v-model="saved"
                                      border="start"
                                      variant="tonal"
                                      closable
                                      close-label="Close Alert"
                                      color="success"
                                      title="Message"
                                    >
                                    <!-- color="deep-purple-accent-4" -->
                                      User registered successfully.
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        
        
          <v-row v-if="loading" >
            <v-col cols="12"  >
            <v-card  >
          <div class="text-xs-center">
              <v-progress-circular
                indeterminate
                :size="50"
                :width="8"
                color="green">
              </v-progress-circular>
            </div>
          </v-card>
          </v-col>
          </v-row>
        <v-row v-else fluid>
        <v-col cols="12">
        <v-card>
          <v-table grid-list-xl
            fixed-header
            height="300px"
          >
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left">Full Name</th>
                <th class="text-left">Email</th>
                <th class="text-left">Role</th>
                <th class="text-left">Created At</th>
                <th class="text-left">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in usersResponse"
                :key="index"
              >
                <td>{{ index+1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.roles[0].name }}</td>
                <td>
                  {{ (item.created_at).split('T')[0] }}
                </td>
                <td>
                  <v-row >
                    <v-btn
                          color="info"  size="x-small"  
                          prepend-icon="mdi-eye" 
                          v-bind="props"
                          :to="`/users/${item.id}`"
                        >
                          Show
                        </v-btn>
                    &nbsp;
                     <v-btn
                          color="green"  size="x-small"  
                          prepend-icon="mdi-pencil" 
                          v-bind="props"
                        >
                          Edit
                        </v-btn>
                    &nbsp;
                    <v-btn 
                    color="error"  size="x-small"  
                    prepend-icon="mdi-delete"                
                    @click="deleteFun(item.id)">Delete</v-btn>
                  </v-row>

                  
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-card>
        </v-col>
        </v-row>
      </v-container>
        
      </v-main>
    </v-layout>
  </v-card>
</template>
<template>
  <v-card  class="mx-auto" color="grey-lighten-3">
    <v-layout>
      <v-container fluid>
          <div class="dashboard">
            <h2>Dashboard <small v-if="userStore.user != null">Welcome, {{ userStore.user.name }}</small>
              <v-divider></v-divider>
              
              
              <!-- {{ (userStore.user.permissions).length }} -->
              
            </h2>
            <v-btn @click="displayPermissions">
              Show
            </v-btn>
            {{ myData }}
            <p v-for="(item, index) in (userStore.user.permissions)"
            :key="index">
              {{ item.name}}
            </p>
            <!--  -->
          </div>
          <v-row dense>
            <v-col cols="3">
              <v-card>
                <v-alert
                  type="success"
                  icon="mdi-account-group-outline"
                  border
                  :title="`Members ${personalInfosResponse.length}`"
                  text=""
                >
                <v-avatar>
                <v-img
                  :src="require('@/assets/trhb-logo.png')"
                  alt="John"
                ></v-img>
              </v-avatar>
                <v-avatar color="info">
                    <span class="text-h5">{{personalInfosResponse.length}}</span>
                  </v-avatar>
                </v-alert>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card>
                <v-alert
                  type="info"
                  icon="mdi-account-group-outline"
                  border
                  :title="`Corporates ${personalInfosResponse.length}`"
                  text=""
                >
                <v-avatar color="success">
                    <span class="text-h5">{{personalInfosResponse.length}}</span>
                  </v-avatar>
                </v-alert>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card>
                <v-alert
                  type="warning"
                  icon="mdi-account-group-outline"
                  border
                  :title="`Invoices ${invoicesResponse.length}`"
                  text=""
                >
                <v-avatar color="error">
                    <span class="text-h5">{{invoicesResponse.length}}</span>
                  </v-avatar>
                </v-alert>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-card>
                <v-alert
                  type="error"
                  icon="mdi-account-group-outline"
                  border
                  :title="`Payments ${paymentsResponse.length}`"
                  text=""
                >
                <v-avatar color="warning">
                    <span class="text-h5">{{paymentsResponse.length}}</span>
                  </v-avatar>
                </v-alert>
              </v-card>
            </v-col>
          </v-row>
          <v-row><br></v-row>
        <v-row dense> 
          <v-col v-for="n in 4" :key="n" cols="6">
            <v-card
              :title="`Content ${n}`"
              :subtitle="`Subtitle for Content ${n}`"
              text="Lorem ipsum dolor sit amet consectetur, adipisicing elit.?"
            ></v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-layout>
  </v-card>
</template>

<script> 
import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useUserStore } from "@/store/auth";
// import VueSession from 'vue-session'
// Vue.use(VueSession)
export default {
      name: 'Dashboard',
      setup() {
        const userStore = useUserStore();

        return { userStore };
      },
      data () {
       return { 
          // user: this.$userStore.state.auth.user,
          country: {countryName: 'Ethiopia', id: '1'},
          state: {countryID: '1', stateName: 'Tigray', id: '1'},
          education: {name: 'Select Education', id: ''},
          memberType: {name: 'Select Member Type', id: ''},
          personalInfosResponse: [],
          invoicesResponse: [],
          paymentsResponse: [],
          myUser: useUserStore(),
          myData: '',
        }
      },
      computed: {
          
      },
      mounted () {

        axios
        .get('/members')
          .then(response => {
            this.personalInfosResponse = response.data.personalInfos;
          })
          .catch(error => {
            console.log(error);
          });
        axios
        .get('/invoices')
          .then(response => {
            this.invoicesResponse = response.data.invoices;
          })
          .catch(error => {
            console.log(error);
          });
        axios
        .get('/payments')
          .then(response => {
            this.paymentsResponse = response.data.payments;
          })
          .catch(error => {
            console.log(error);
          });
      },
      // beforeCreate: function () {
        // if (!this.$session.exists()) {
        //   this.$router.push('/')
        // }
      // },
      methods: {
        displayPermissions(){
          this.myData = (this.myUser.user.roles.permissions);
          // this.myData = JSON.parse(this.myUser.user);
          // this.myData = this.myUser.permissions;
          // return;
          // for (let i = 0; i < this.myUser.user.permissions.length; i++) {
          //  this.myData += (`Index ${i}: ${this.myUser.user.permissions[i]}`);
          // }
        },
        submit(){
          
        },
      },
}
</script>

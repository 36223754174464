<template>
  <v-app>
    <div v-if="currentRouteName === 'login'"> </div>
    <!-- <div v-else-if="currentRouteName === 'signin'"> </div> -->
    <div v-else-if="currentRouteName === 'forgot-password'"> </div>
    <!-- <Navbar v-else/> -->
    <NavbarWeb v-else/>
    <v-main style="background-color:#f8f8f8">
      <router-view/>
    </v-main>

    <v-row style="background-color: #081222; color:white; bottom: 0px; margin-bottom: 0px; position: auto;">
      <v-col cols="12" md="12" sm="12" style="background-color: #081222;">
        <v-footer color="" class="justify-center " style="background-color: #0D1B34; color:white; " dark>
          <v-row class="mx-7">
            <v-col cols="12" md="3"
            >
              <span class="ml-10 "><h3> {{$t('get_updates')}}</h3></span>
              <v-list  style="background-color: #0D1B34; color:white;">
                <v-list-item link  
                  transparent="1"
                  prepend-icon="mdi-newspaper"
                  v-for="(category, index) in categoriesResponse"
                  :key="index"
                  :href="`/#/posts/${category.slug}`">
                  {{ trans(category.id, category, 'name') }}
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="3"
            >
              <span class="ml-10 "><h3> {{$t('resources')}}</h3></span>
              <v-list  style="background-color: #0D1B34; color:white;"                
              >
                <v-list-item transparent="1"
                v-for="(resourceType, index) in resourceTypes"
                  :prepend-icon="resourceType.icon"
                  :to="`/resources/${resourceType.slug}`"
                >
                {{ trans(resourceType.id, resourceType, 'name') }}
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="3"
            >
              <span class="ml-10 "><h3> {{$t('important_links')}}</h3></span>              
              <v-list  style="background-color: #0D1B34; color:white;">
                <v-list-item transparent="1"
                  v-for="link in linksResponse"                
                  prepend-icon="mdi-link"
                >
                  <a :href="`${link.url}`" target="_blank" 
                    style="text-decoration:none; color:white">
                    {{ trans(link.id, link, 'title') }}
                  </a>                  
                </v-list-item> 
              </v-list>
            </v-col>
            <v-col cols="12" md="3"
            >
              <span class="ml-10 "><h3>  {{$t('contact_us')}}</h3></span>
              <v-list  style="background-color: #0D1B34; color:white;">
                <v-list-item transparent="1"
                  prepend-icon="mdi-phone"
                >
                  {{ telephone }}<br> {{ telephone2 }}
                </v-list-item>
                <v-list-item transparent="1"
                  prepend-icon="mdi-email"
                >
                  {{ email }}
                </v-list-item>                
                <v-list-item transparent="1"
                  prepend-icon="mdi-message"
                >
                  <a href="/#/contact-us" style="text-decoration:none; color:white">{{$t('sendusmessage')}}</a>
                </v-list-item>
                <v-list-item transparent="1"
                  prepend-icon="mdi-map-marker"
                >
                  {{$t('mekelle')}}
                </v-list-item>
              </v-list>              
            </v-col>
              <!-- Scroll to Top button -->
            <v-btn v-if="showScrollToTop" fab dark bottom right @click="scrollToTop" color="blue lighten-1" class="scroll-to-top" icon>
              <v-icon>mdi-chevron-double-up</v-icon>
            </v-btn>
            <v-list v-if="mobile" fab dark bottom right class="floating-sm" transparent>
                <v-list-item v-for="socialMedia in socialMediasResponse"
                    :key="socialMedia.id">
                  <span  >
                    <v-tooltip 
                      v-if="socialMedia.status"
                      :text="socialMedia.title" location="top">
                      <template v-slot:activator="{ props }">
                        <v-btn :icon="`mdi-${socialMedia.icon}`" :href="`${socialMedia.url}`" color="transparent" density="comfortable" elevation="1" v-bind="props"></v-btn>
                      </template>
                    </v-tooltip>
                    &nbsp; &nbsp; 
                  </span> 
                </v-list-item>
              </v-list> 
            <!-- <v-btn   @click="scrollToTop" color="blue lighten-1" class="floating-sm" icon>
              <v-icon>mdi-home</v-icon>
            </v-btn> -->
          </v-row>
        </v-footer> 
        <v-footer color="" class="justify-center " style="background-color: #081222; color:white; max-height: 100px;" dark>
          <v-row>
            <v-col cols="12" md="6" sm="12"> <br>
              <span>&copy; {{ new Date().getFullYear() }} Agency Digital Tigray</span>
              <!-- This is {{ trans('home') }} -->
              <span>&nbsp; &nbsp; | &nbsp; &nbsp; Developed by: <a href="https://www.pilasatech.net" target="_blank" style="text-decoration:none; color: #b3e5fc"><b>Pilasa Technologies </b></a> &nbsp; | &nbsp; 
                <a  href="/#/developer-feedback"  style="text-decoration:none; color: #b3e5fc"><b>Feedback</b></a>
               
              </span>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              
              <v-list  style="background-color: #081222; color:white; float: right;">
                <v-list-item>
                  <span class="ml-15 "> {{$t('find_us_on')}}</span> &nbsp; &nbsp; 
                  <span v-for="socialMedia in socialMediasResponse"
                    :key="socialMedia.id" >
                    <v-tooltip 
                      v-if="socialMedia.status"
                      :text="socialMedia.title" location="top">
                      <template v-slot:activator="{ props }">
                        <v-btn :icon="`mdi-${socialMedia.icon}`" :href="`${socialMedia.url}`" color="transparent" density="comfortable" elevation="1" v-bind="props"></v-btn>
                      </template>
                    </v-tooltip>
                    &nbsp; &nbsp; 
                  </span> 
                </v-list-item>
              </v-list> 
            </v-col>
          </v-row>
          
        </v-footer>
      </v-col>
    </v-row>    
  </v-app>
</template>

<script>
import NavbarWeb from '@/components/NavbarWeb.vue';
import { defineComponent } from 'vue';
import axios from 'axios';
export default defineComponent({
  name: 'App',
  components: {
    NavbarWeb,
  },
  data: () => ({
    //
    show: false,
    mobile: false,
    windowWidth: null,
    showScrollToTop: false,
    resourceTypes: [],
    categoriesResponse: [],
    socialMediasResponse: [],
    linksResponse: [],
    
    settingsResponse: [],

    activeLng: null,

    // Declare a global variable
    fontFamily: 'sans-serif',
    logo: '',
    title: '',
    moto: '',
    enableDonate: '',
    telephone: '',
    telephone2: '',
    email: '',
    feedback: false,
    topbar: false,

    langs : [
      // English
      [
        home  => 'Home',
        about =>  'About us',
      ],
      // Tigrigna
      [
        home  => 'ፈላሚ ገፅ',
        about =>  'ብዛዕባና',
      ],
      // Amharic
      [
        home  => 'ቀዳሚ ገፅ',
        about =>  'ስለ እኛ',
      ],
    ],      
    // Declare a global variable
    globalImgUrl: 'https://www.tigdigital.gov.et/adt/public/',
  }),
  computed: {
    currentRouteName(){
      return this.$route.name;
    }
  },
  mounted () {      
    window.addEventListener("resize",this.checkscreen);
    this.getResourceTypes();
    this.getCategories();
    this.getSocialMedias();
    this.getLinks();
    this.getLocalSettings();
    this.getSettings();
    // Add a scroll event listener when the component is mounted
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
     // Remove the scroll event listener when the component is destroyed
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    /*trans(keyword){
      let i = 0;
      switch(keyword){
        case 'home':
          return this.langs[0][keyword];
        default:
          return "I don't know";
      }
    },*/
    getActiveLng(){
      if (this.$i18n.locale == "En") {
        this.activeLng = "En"
      } else if (this.$i18n.locale == "ትግ") {
        this.activeLng = "ti"
      } else if (this.$i18n.locale == "ኣማ") {
        this.activeLng = "am"
      }
    },
    trans(id, str, col){ 
      this.getActiveLng();
      let translation = null;
      if(str.translations.length > 0)
        translation = str.translations.find(
          translation => translation.locale === this.activeLng &&
          translation.column_name === col
        );
      if(col == 'title')
        return translation ? translation.value : str.title;
      else if(col == 'name')
        return translation ? translation.value : str.name;
    },
    checkscreen(){
        this.windowWidth = window.innerWidth;
        this.mobile = this.windowWidth <= 650;
      },
    handleScroll() {
      // Check if the user has scrolled down a certain amount
      this.showScrollToTop = window.scrollY > 100;
    },
    scrollToTop() {
      // Scroll to the top of the page
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    async getResourceTypes(){
      await axios.get('/resource-types')
        .then(response => {
          this.resourceTypes = response.data.mydata
        })
        .catch(error => {
          console.log(error)
        });
    },
    async getCategories(){
      await axios.get('/categories')
        .then(response => {
          this.categoriesResponse = response.data.mydata
        })
        .catch(error => {
          console.log(error)
        });
      },
    async getSocialMedias(){
      await axios.get('/social-medias')
        .then(response => {
          this.socialMediasResponse = response.data.mydata
        })
        .catch(error => {
          console.log(error)
        });
    },
    async getLinks(){
      await axios.get('/links')
        .then(response => {
          this.linksResponse = response.data.mydata
        })
        .catch(error => {
          console.log(error)
        });
    },

    getLocalSettings(){
      let storedMenu = localStorage.getItem('storedMenu');
      this.allMenuResponse = JSON.parse(storedMenu);
      let storedTransMenu = localStorage.getItem('storedTransMenu');
      this.allTransMenuResponse = JSON.parse(storedTransMenu);
    },
    async getSettings(){
      await axios.get('/settings')
        .then(response => {
          this.settingsResponse = response.data.mysettings.Site;

          this.logo = this.settingsResponse.find((setting) => setting.key === 'site.logo').value;
          this.fontFamily = this.settingsResponse.find((setting) => setting.key === 'site.menuFontFamily').value;
          this.title = this.settingsResponse.find((setting) => setting.key === 'site.title').value;
          this.moto = this.settingsResponse.filter(item => item.key === "site.moto")[0].value;
          this.telephone = this.settingsResponse.filter(item => item.key === "site.telephone")[0].value;
          this.telephone2 = this.settingsResponse.filter(item => item.key === "site.telephone2")[0].value;
          this.email = this.settingsResponse.filter(item => item.key === "site.email")[0].value;
          this.feedback = this.settingsResponse.filter(item => item.key === "site.feedback")[0].value;
          this.topbar = this.settingsResponse.filter(item => item.key === "site.topbar")[0].value;

          localStorage.setItem('storedMenu', );


        })
        .catch(error => {
          console.log(error)
        });

    },
  }
});
</script>

<style scoped>
.scroll-to-top {
  position: fixed;
  bottom: 96px;
  right: 16px;
}
.floating-sm {
  position: fixed;
  bottom: 200px;
  right: 6px;
}
</style>
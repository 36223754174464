// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import { VBtn } from 'vuetify/components'
import { VCalendar } from 'vuetify/labs/VCalendar'

// import { aliases, fa } from 'vuetify/iconsets/fa'
// import { mdi } from 'vuetify/iconsets/mdi'

export default createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  aliases: {
    VBtnSecondary: VBtn,
    VBtnTertiary: VBtn,
  },
  components: {
    VCalendar,
  },
  /*icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      fa,
      mdi,
    },
  },*/
  // defaults: {
  //   VBtn: {
  //     color: 'primary',
  //     variant: 'text',
  //   },
  //   VBtnSecondary: {
  //     color: 'secondary',
  //     variant: 'flat',
  //   },
  //   VBtnTertiary: {
  //     rounded: true,
  //     variant: 'plain',
  //   },
  // },
})

<template>
      <v-sheet >
        <slider/>
        <!-- <calendar/> -->
        <trends/>
        <!-- <products/> -->
        <services/>
        <updates/>
        <partners/>
        <newsletter/>
        <!-- <v-row class="mb-1 pb-6">
          
        </v-row> -->
      </v-sheet>
</template>
<script>
import axios from 'axios';
import slider from './slider/Slider.vue';
import trends from './trends/Trends.vue';
// import calendar from './splash_calendar/calendar.vue';
import products from './products/Products.vue';
import services from './services/Services.vue';
import updates from'./news/updates.vue'
import partners from './partners/Partners.vue';
import newsletter from './newsletter/Newsletter.vue';

export default {
  name: 'HelloWorld',
  components: {
    slider,
    // calendar,
    trends,
    products,
    services,
    updates,
    partners,
    newsletter,
  },
  
}
</script>
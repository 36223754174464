<template>
	<v-sheet>
		<v-row class="justify-center mb-7 mt-12">
      <span class="text-h4" style="border-bottom:5px dashed #039be5;">Our Products</span>
    </v-row>
    <v-row class="justify-center  mb-7 mt-6 mx-15">
      <span class="text-h5 text-center">We are pleased to offer a comprehesive range of products</span>
    </v-row>
    <v-row :class="mobile===true ? 'mx-1' : 'mx-15'">
      <v-col 
      	v-for="(product, i) in productsResponse"
        :key="i" cols="12" sm="6" md="3">
        <div>
          <v-hover v-slot="{ isHovering, props }">
            <v-card
              class="mx-auto"
              max-width="344"
              v-bind="props"
            >
              <v-img 
              :src="`${$root.globalImgUrl}${product.image}`" height="200"
              class="bg-grey-lighten-2"
              ></v-img>
              <v-card-text>
                <h2 class="text-h6 text-primary">
                  {{ product.name }}
                </h2>
                <div v-html="product.description"></div>
              </v-card-text>
              <v-overlay
                :model-value="isHovering"
                contained
                scrim="#036358"
                class="align-center justify-center"
              >
                <v-btn variant="flat">See more info</v-btn>
              </v-overlay>
            </v-card>
              </v-hover>
            </div>
          </v-col>
        </v-row>
	</v-sheet>
</template>
<script>
import axios from 'axios';

export default {
  name: 'Products',
  components: {
    // slider,
  },
  data: () => ({
    productsResponse: [],
  }),
  mounted () {         
      this.getproducts();
    },

  methods: { 
    getproducts(){
      axios
      .get('/products')
        .then(response => {
          this.productsResponse = response.data.mydata
        })
        .catch(error => {
          console.log(error)
        });
    },

  }
}
</script>

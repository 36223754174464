<template>
	<v-sheet style="background-color:#E1F5FE">
		<v-row class="justify-center mb-7 mt-5 pt-5">
          <span class="text-h4" style="border-bottom:5px dashed #0D1B34;">Our Services</span>
        </v-row>
        <v-row class="justify-center  mb-7 mt-6 mx-15">
          <span class="text-h5 text-center">We are pleased to offer a comprehesive range of services</span>
        </v-row>
		<v-row class="ma-5 pb-7">
			<v-col cols="12" sm="12" md="3" 
				v-for="service in servicesResponse"
			>
        <v-hover
          v-slot="{ isHovering, props }"
          open-delay="200"
        >
  				<v-card 
            :elevation="isHovering ? 10 : 1"
          :class="{ 'on-hover': isHovering }"
          class="mx-auto pa-5"          
          v-bind="props"
          >
  					<v-row class="justify-center mt-2" >						 
  						<v-avatar   size="150">
                <v-img 
                :src="require('@/assets/service-icon.png')"
                height="200"
                class="bg-grey-lighten-2"
                ></v-img>
  							<!-- <v-icon color="#039be5" size="100"> mdi-star </v-icon>  -->
  						</v-avatar>
  					</v-row>
  					<v-row class="justify-center text-center">
              <v-list-item 
                link  style="color: #0D1B34;"
                :to="`/services/${service.slug}`" >
                <b> {{trans(service.id, service, 'name')}}</b>
              </v-list-item>
  					</v-row>
  					<v-row class="justify-center pa-5">
  						<div v-html="trans(service.id, service, 'description')"></div>
  					</v-row>
  				</v-card>
        </v-hover>
			</v-col>
		</v-row>
	</v-sheet>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Services',
  components: {
    // slider,
  },
  data: () => ({
    servicesResponse: [],
    loading: true,
  }),
  mounted () {         
      this.getServices();

    },

  methods: {   
    trans(id, str, col){

      const translation = str.translations.find(
        translation => translation.locale === this.$root.activeLng &&
        translation.column_name === col
      );
      if(col == 'name')
        return translation ? translation.value : str.name;
      else if(col == 'description')
        return translation ? translation.value : str.description;
    }, 

    getServices(){
      axios
      .get('/services')
        .then(response => {
          this.servicesResponse = response.data.mydata.filter(item => item.status === 1);
          this.loading = false
        })
        .catch(error => {
          console.log(error)
        });
    },

  }
};
</script>

<style lang="sass" scoped>
.v-card.on-hover.v-theme--dark
  background-color: rgba(#FFF, 0.8)
  >.v-card__text
    color: #000
</style>
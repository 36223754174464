import Vue from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
// import VueRouter from 'vue-router'
import store from '@/store'
import { useUserStore } from "@/store/auth";

import Dashboard from '../views/Dashboard.vue'
import HomeView from '../views/HomeView.vue'
import Login from '../views/auth/Login.vue'
import UsersVuex from '../views/auth/UsersVuex.vue'
import UsersPinia from '../views/auth/UsersPinia.vue'
import Register from '../views/auth/Register.vue'
import UserShow from '../views/auth/UserShow.vue'

import RolesIndex from '../views/roles/RolesIndex.vue'
import RoleCreate from '../views/roles/RoleCreate.vue'
import RoleEdit from '../views/roles/RoleEdit.vue'

 
// import Index from '../components/members/Index.vue'
import Settings from '../views/settings/Settings.vue'

/* Guest Component */
  // const Login = () => import('@/components/Login.vue')
  // const Register = () => import('@/components/Register.vue')
  
  const ManualIndex = () => import('@/views/manual/ManualIndex.vue')
  const PNF = () => import('@/components/404.vue')
/* Guest Component */

// const userStore = useUserStore();

// Vue.use(VueRouter)
// Define scroll behavior to scroll to top on route change
const scrollBehavior = (to, from, savedPosition) => {
  // If the route has a hash, scroll to the hash
  if (to.hash) {
    return { selector: to.hash };
  } else {
    // Otherwise, scroll to top
    return { top: 0 };
  }
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      middleware: "guest",
      title: "Home"
    }
  },
  {
    path: '/products',
    name: 'products',
    component: () => import('../views/products/ProductIndex.vue'),
    meta: {
      middleware: "guest",
      title: "Products"
    }
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('../views/services/ServiceIndex.vue'),
    meta: {
      middleware: "guest",
      title: "Services"
    }
  },
  {
    path: '/services/:slug',
    name: 'serviceDetail',
    component: () => import('../views/services/ServiceDetail.vue'),
    meta: {
      middleware: "guest",
      title: "Service Detail"
    }
  },
  // Sectors
  {
    path: '/sectors',
    name: 'sectors',
    component: () => import('../views/sectors/SectorIndex.vue'),
    meta: {
      middleware: "guest",
      title: "Sectors"
    }
  },
  {
    path: '/sectors/:slug',
    name: 'sectorDetail',
    component: () => import('../views/sectors/SectorDetail.vue'),
    meta: {
      middleware: "guest",
      title: "Sector Detail"
    }
  },
  // Sectors
  {
    path: '/directorates',
    name: 'directorates',
    component: () => import('../views/directorates/DirectorateIndex.vue'),
    meta: {
      middleware: "guest",
      title: "directorates"
    }
  },
  {
    path: '/directorates/:slug',
    name: 'directorateDetail',
    component: () => import('../views/directorates/DirectorateDetail.vue'),
    meta: {
      middleware: "guest",
      title: "Directorate Detail"
    }
  },
  {
    path: '/about/goals',
    name: 'goals',
    component: () => import('../views/about/goals.vue'),
    meta: {
      middleware: "guest",
      title: "Goals"
    }
  },
  {
    path: '/about/gallery',
    name: 'gallery',
    component: () => import('../views/about/gallery.vue'),
    meta: {
      middleware: "guest",
      title: "Gallery"
    }
  },
  {
    path: '/about/teams',
    name: 'teams',
    component: () => import('../views/about/teams.vue'),
    meta: {
      middleware: "guest",
      title: "Teams"
    }
  },
  {
    path: '/about/organogram',
    name: 'organogram',
    component: () => import('../views/about/organogram.vue'),
    meta: {
      middleware: "guest",
      title: "Organogram"
    }
  },
  {
    path: '/pages/:slug',
    name: 'pages',
    component: () => import('../views/pages/pages.vue'),
    props: true,
    meta: {
      middleware: "guest",
      requiresAuth: false,
      title: "Pages"
    }
  },
  {
    path: '/about/health-facilities',
    name: 'healthFacilities',
    component: () => import('../views/about/health-facilities.vue'),
    meta: {
      middleware: "guest",
      title: "Health Facilities"
    }
  },
  {
    path: '/about/health-facilities/:slug',
    name: 'healthFacilitiesDetails',
    component: () => import('../views/about/hf-details.vue'),
    meta: {
      middleware: "guest",
      title: "Health Facility Detail"
    }
  },
  {
    path: '/posts/:slug',
    name: 'news',
    component: () => import('../views/news/News.vue'),
    props: true,
    meta: {
      middleware: "guest",
      title: "News"
    }
  },
  {
    path: '/posts/:catslug/:postslug',
    name: 'newsdetail',
    component: () => import('../views/news/news-detail.vue'),
    meta: {
      middleware: "guest",
      title: "News"
    }
  },
  {
    path: '/calendars',
    name: 'calendars',
    component: () => import('../views/calendars/calendars.vue'),
    meta: {
      middleware: "guest",
      title: "Calendars"
    }
  },
  {
    path: '/vacancies',
    name: 'vacancies',
    component: () => import('../views/vacancies/VacanciesIndex.vue'),
    meta: {
      middleware: "guest",
      title: "Vacancies"
    }
  },
  {
    path: '/vacancies',
    name: 'vacancies',
    component: () => import('../views/vacancies/VacanciesIndex.vue'),
    meta: {
      middleware: "guest",
      title: "Vacancies"
    }
  },
  {
    path: '/tenders',
    name: 'tenders',
    component: () => import('../views/tenders/TendersIndex.vue'),
    meta: {
      middleware: "guest",
      title: "Tenders"
    }
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: () => import('../views/faqs/faqs.vue'),
    meta: {
      middleware: "guest",
      title: "FAQs"
    }
  },
  {
    path: '/polls',
    name: 'polls',
    component: () => import('../views/polls/polls.vue'),
    meta: {
      middleware: "guest",
      title: "Polls"
    }
  },
  {
    path: '/contact-us',
    name: 'contacts',
    component: () => import('../views/contacts/ContactUsIndex.vue'),
    meta: {
      middleware: "guest",
      title: "Contact Us"
    }
  },
  {
    path: '/donate',
    name: 'donate',
    component: () => import('../views/donate/donate.vue'),
    meta: {
      middleware: "guest",
      title: "Donate"
    }
  },
  {
    path: '/resources',
    name: 'resources',
    component: () => import('../views/resources/resources.vue'),
    meta: {
      middleware: "guest",
      title: "Resources"
    }
  },
  {
    path: '/resources/:slug',
    name: 'resources',
    component: () => import('../views/resources/resources.vue'),
    meta: {
      middleware: "guest",
      title: "Resources"
    }
  },
  {
    path: '/forums',
    name: 'forums',
    component: () => import('../views/forum/Forum.vue'),
    meta: {
      middleware: "guest",
      title: "Forum"
    }
  },
  {
    path: '/forums/detail/:slug',
    name: 'forumDetail',
    component: () => import('../views/forum/ForumDetail.vue'),
    props: true,
    meta: {
      middleware: "guest",
      title: "Forums"
    }
  },
  {
    path: "/replies",
    name: "Fetch All Comments",
    component: () => import('../views/forum/ForumDetail.vue'),
    meta: {
      middleware: "guest",
      title: "All replies"
    }
  },
  {
    path: "/repies",
    name: "Save Comment",
    component: () => import("../components/forums/comment-form"),
    meta: {
      middleware: "guest",
      title: "Save reply"
    }
  },
  {
    path: '/search/:keyword',
    name: 'search',
    component: () => import('../views/search/search.vue'),
    meta: {
      middleware: "guest",
      title: "Search"
    }
  },
  {
    path: '/developer-feedback',
    name: 'developer',
    component: () => import('../views/developer/developer.vue'),
    meta: {
      middleware: "guest",
      title: "Search"
    }
  },
  
  // PNF - Page Not Found
  {
    path: '/:pathMatch(.*)*',
    name: 'pageNotFound',
    component: PNF,
    meta: {
      middleware: "guest",
      title: "Page Not Found"
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      middleware: "auth",
      title: "Dashboard"
    },
    /*beforeEnter(to, from, next) {
      const userStore = useUserStore();
      if (userStore.authenticated) {
        next();
      } else {
        next("/signin");
      }
    }*/
  },
  // login
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      middleware: "guest",
      title: "Login"
    }
  },
  {
    path: '/users-vuex',
    name: 'usersVuex',
    component: UsersVuex,
    meta: {
      middleware: "auth",
      title: "UsersVuex"
    }
  },
  {
    path: '/users-pinia',
    name: 'usersPinia',
    component: UsersPinia,
    meta: {
      middleware: "auth",
      title: "UsersPinia"
    }
  },
  // Users
  {
    path: '/users',
    name: 'register',
    component: Register,
    meta: {
      middleware: "auth",
      title: "Users"
    }
  },
  {
    path: '/users/:id',
    name: 'userShow',
    component: UserShow,
    meta: {
      middleware: "auth",
      title: "User Detail"
    }
  },  // Roles

  {
    path: '/roles',
    name: 'roles',
    component: RolesIndex,
    meta: {
      middleware: "auth",
      title: "Roles"
    }
  },
  {
    path: '/roles/create',
    name: 'roleCreate',
    component: RoleCreate,
    meta: {
      middleware: "auth",
      title: "Create Role"
    }
  },
  {
    path: '/roles/:id/edit',
    name: 'roleEdit',
    component: RoleEdit,
    meta: {
      middleware: "auth",
      title: "Edit Role"
    }
  },
  // Register
  {
    path: '/register',
    name: 'register2',
    component: Register,
    meta: {
      middleware: "auth",
      title: "Register"
    }
  },
  // Members
   
 
  // Settings
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      middleware: "auth",
      title: "Settings"
    }
  },
  // Manual
  {
    path: '/manual',
    name: 'manual',
    component: ManualIndex,
    meta: {
      middleware: "auth",
      title: "Manual"
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/auth/Profile.vue'),
    meta: {
      middleware: "auth",
      title: "Profile"
    }
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('../views/auth/ChangePassword.vue'),
    meta: {
      middleware: "auth",
      title: "Change Password"
    }
  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import('../views/auth/Signin.vue'),
    meta: {
      middleware: "guest",
      title: "Signin"
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('../views/auth/ForgotPassword.vue'),
    meta: {
      middleware: "guest",
      title: "Forgot Password"
    }
  },
  // {
  //   path: '/signup',
  //   name: 'signup',
  //   component: () => import('../views/auth/Signup.vue')
  // },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/about/index.vue'),
    meta: {
      middleware: "guest",
      title: "About"
    }
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/test/test.vue'),
    meta: {
      middleware: "guest",
      title: "test"
    }
  },
]


const router = createRouter({
  history: createWebHashHistory(),
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + " - Agency Digital Tigray";
  const userStore = useUserStore();
  if (to.meta.middleware == "guest") {
      if (localStorage.getItem('authenticated')) {
      // if (userStore.authenticated) {
        next({ name: "dashboard" })
      } else {
        next()
      }
  } 
  else {
      if (localStorage.getItem('authenticated')) {
      // if (userStore.authenticated) {
          next()
      } else {
          next({ name: "signin" })
      }
  }
})

export default router

// stores/users.js
import { defineStore } from 'pinia'
// Import axios to make HTTP requests
import axios from "axios"
import { useRouter } from 'vue-router';
// import VueSession from 'vue-session'
// Vue.use(VueSession)

export const useUserStore = defineStore("auth",{
    // user: 
    state: () => ({        
        // if (localStorage.getItem("user"))
        //   return JSON.parse(localStorage.getItem("user"));
        // return {
          users: [],
          authenticated: localStorage.getItem('authenticated') || false,
          user: null,
          user: JSON.parse(localStorage.getItem('user') || null),
          router: useRouter(),
        // };
        

    }),
    getters: {
        authenticate(state){
            return state.authenticated
        },
        getUsers(state){
            return state.users
        },
        getUser(state){
            return state.user
        }
    },
    actions: {
        async fetchUser() {
          const res = await fetch("http://localhost:8081/api/user");
    
          const user = await res.json();
          this.user = user;
        },

        async signUp(email, password) {
          const res = await fetch("http://localhost:8081/api/register", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, password }),
          });
          const user = await res.json()
          this.user = user;
        },

        async signIn(email, password) {
          try {                
              /*const response =   axios.post("/login", {
                  email: email,
                  password: password
                }, {
                  headers: {'Content-Type': 'application/json'}
                }).catch(function(error) {
                  console.log(error);
                });*/

              await axios.post('/login', {
                  email: email,
                  password: password
                  }, {
                  headers: {'Content-Type': 'application/json'}
                })
                .then(response => {
                  if (response.status === 200 && 'accessToken' in response.data) {
                    // this.$session.start();
                    // this.$session.set('jwt', response.data.accessToken);

                    this.user = response.data.user;
                    this.authenticated = true;
                    localStorage.setItem('user', JSON.stringify(response.data.user));
                    localStorage.setItem('authenticated', true);
                    localStorage.setItem('token', response.data.accessToken);
                  
                    // Vue.http.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;
                  } else if (response.status === 401) {
                    this.user = null;
                    this.authenticated = false;
                    localStorage.clear();
                  } //else 
                    //this.$router.push('/panel/search')
                  return response;
                  
                })
                .catch(error => {
                  console.log(error);
                  this.authenticated = false;
                });
              // const res = response;
              // if (!res.ok) {
              //     throw new Error(`Error! status: ${res.status}`);
              // }
              
              // this.user  = response.data.user;
              // console.log(`User: ${this.user}`);
              
          } catch (err) {
          // console.log(err);
        }
        },
      //
      async fetchUsers() {
          try {
            const data = await axios.get(
              'http://localhost:8081/api/users'
              // 'https://jsonplaceholder.typicode.com/users'
              )
              this.users = data.data.users
              // this.users = data.data
            }
            catch (error) {
              alert(error)
              console.log(error)
          }
      },
      logout(){
          this.authenticated = false;
          this.user = null;
          // this.$session.destroy();
          localStorage.removeItem('authenticated');
          localStorage.removeItem('user');
          localStorage.clear();
      }
    },
    
    persist: {
      storage: localStorage, // data in localStorage is cleared when ... .
      // storage: sessionStorage, // data in sessionStorage is cleared when the page session ends.
    },
  }
)
<script>
import { ref } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

export default {
  name: 'Login',
  data () {
        return { 
          user: null,
          
        }
    },
  setup() {
    const email = ref('');
    const password = ref('');
    const router = useRouter();

    const submit = async () => {
      // let data = JSON.stringify({
      //   email: email.value,
      //   password: password.value
      // });
      // const response = await axios.post('/login',data,{headers:{"Content-Type" : "application/json"}});

      const response =  await axios.post("/login", {
        email: email.value,
        password: password.value
      }, {
        headers: {'Content-Type': 'application/json'}
      }).then(response => {
        console.log(response);
      }).catch(function(error) {
        console.log(error);
      });

      // const response = await axios.post('/login', {
      //   email: email.value,
      //   password: password.value,
      // });
      // this.user = response.user;
      // localStorage.setItem('token', response.accessToken);
      await router.push({ path: '/dashboard' });
    };

    return {
      email,
      password,
      submit,
    };
  },
};
</script>

<template>
  <v-layout class="text-center">
    <v-container fluid>
      <v-card class="mx-auto"
        elevation="12"
        max-width="400">
          <h2 class="text-3xl font-semibold text-center text-purple-700">
            <v-img
                :src="require('@/assets/trhb-logo.png')"
                class="my-3"
                contain
                height="80"
              />
              <small>Membership System</small>
          </h2>
          <v-card-title>Login</v-card-title>
          <v-card-subtitle>Authenticate your self to use the system</v-card-subtitle>
          <v-card-text>
          <form class="space-y-4" @submit.prevent="submit">
            <div>
              <v-text-field
                  v-model="email"
                  label="Email"
                  type="email"
                  prepend-icon="mdi-account"
                  required
              ></v-text-field>
            </div>
            <div>
              <v-text-field
                  v-model="password"
                  name="password"
                  type="password"
                  label="Password"
                  prepend-icon="mdi-key"
                  required
              ></v-text-field>
            </div>
            <div>
              <v-btn type="submit" color="success"
                  class="mt-4">
                Login &nbsp; 
                <v-icon>mdi-login</v-icon>
              </v-btn>
              
            </div>
          </form>
          <p class="mt-8 text-xs font-light text-center text-gray-700">
            Do not have an account?
            <RouterLink
              to="/register"
              class="font-medium text-purple-600 hover:underline"
              >Register</RouterLink
            >
          </p>
        </v-card-text>
        <v-card-actions>
     
        </v-card-actions>
      </v-card>
    </v-container>
  </v-layout>
</template>
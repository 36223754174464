<template>
  <v-app-bar
        v-if="$root.topbar > 0"
        app
        dark
        prominent
        elevation="0"
        style="background-color: #1846a4; color: white;"
        :style="{ top: visible ? 0 : '-65px' }"
      >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
        ></v-img>
      </template>
      <v-icon class="ml-4" v-if="mobile" @click="drawerOpen = !drawerOpen">mdi-menu</v-icon>
      <div >
        <div style="float: left; margin-left: 10px;">
            <v-img
            :src="require('@/assets/tigray-flag.png')"
            class="ml-3"
            alt="John" 
            v-if="!mobile"
            height="20px"
            width="50px"
            to="/"
            style=" float:left; margin-top: 7px;"
          ></v-img>
          <span style="float:left; margin-top: 5px;">Tigray Regional State</span>
        </div>
        
      </div>

      <v-spacer></v-spacer> 

      <v-tooltip text="Call Us" location="top">
          <template v-slot:activator="{ props }">
            <v-btn prepend-icon="mdi-phone" :href="telephoneLink"  v-bind="props">{{company.phone}}</v-btn> 
          </template>
        </v-tooltip> 
            
        <v-tooltip text="Mail Us" location="top">
          <template v-slot:activator="{ props }">
            <v-btn lowercase prepend-icon="mdi-email" :href="emailLink" v-bind="props" style="text-transform: lowercase;">{{(company.email).toLowerCase().trim()}} </v-btn>
          </template>
        </v-tooltip>
            
      
      <!-- <calendar/>   -->
      <span class="mr-8"></span>       
      
  </v-app-bar>  
  <v-app-bar app  fixed
      height="100"
      color="menubg ? 'light-blue-darken-1' : ''"  
      :style="`color: #1846a4; border-bottom:3px solid #1846a4; font-family: ${$root.fontFamily}; top: ${visible ? (mobile ? '-0px' : ($root.topbar > 0 ? '64px' : '0px')) : '0px'}`"
      >       
      <v-icon class="ml-4" v-if="mobile" @click="drawerOpen = !drawerOpen">mdi-menu</v-icon>
      <v-img
        :src="`${$root.globalImgUrl}${$root.logo}`"
        class="ml-3"
        alt="John" 
        v-if="!mobile"
        height="90%"
        to="/"
      ></v-img>  
      <h3 style="padding-left:10px;" >      
        <a href="/#" style="text-decoration:none; color:#1846a4;">
          <!-- <span v-if="mobile">TRHB</span> -->
          <span  v-html="`${$root.title}`"></span>
        </a>
      </h3>
      <v-spacer></v-spacer> 
      <span v-for="menu in parentMenus">
        <span v-if="getChildren(menu.id).length > 0">
          <v-menu
            v-if="!mobile"
            open-on-hover            
          >
            <template v-slot:activator="{ props }">
              <v-btn    
                v-bind="props"
                append-icon="mdi-chevron-down"
                style="text-transform:none !important; color: #1846a4; max-width: 150px; text-overflow: text-wrap;"
              >
                {{ tranMenu(menu.title, menu.id) }}
              </v-btn>
            </template>
            <v-list :style="`font-family: ${$root.fontFamily}`">
              <v-list-item transparent="1"
                v-for="(submenu, index) in getChildren(menu.id)"
                :to="`${submenu.url}`"
              class="menu-item" > <v-icon>mdi-chevron-right </v-icon> 
              <!-- {{ submenu.title }} --> {{ tranMenu(submenu.title, submenu.id) }}
              </v-list-item>
            </v-list>
          </v-menu>
        </span>
        <span v-else>
          <v-list-item v-if="!mobile" link :to="`${menu.url}`" style="color: #1846a4; max-width: 150px; text-overflow: text-wrap;">
          <v-icon v-if="menu.icon_class != null"> mdi-{{ menu.icon_class }} </v-icon> {{ tranMenu(menu.title, menu.id) }}
        </v-list-item>
        </span>
      </span>
      
      <!-- Button to Open Search Overlay -->
      <v-btn  class="ml-5" size="small" elevation="1" icon="mdi-card-search" color="menubg ? '#1846a4' : '#1846a4'" style="color: #1846a4"></v-btn>
      <!-- <v-icon @click="openSearchOverlay" color="menubg ? '#1846a4' : '#1846a4'" style="color: #1846a4">mdi-card-search</v-icon> -->
      &nbsp; &nbsp; 
        <!-- Search Overlay --> <!--  -->
      <v-dialog v-model="searchOverlay"  max-width="600px"> <!-- persistent -->
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="searchQuery"
              label="Search"
              @keydown.enter.prevent="submitSearch"
              ref="searchField"
              clearable
              append-inner-icon="mdi-card-search"
              variant="solo"
            ></v-text-field>
          </v-card-title>        
          <!-- Additional Search Form Fields and UI -->        
          <v-card-actions>
            <v-btn @click="closeSearchOverlay" :to="`/search/${searchQuery}`" color="primary">Search</v-btn>
            <v-btn @click="closeSearchOverlay" text>Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
       <!-- Clickable Overlay to Close Search -->
      <v-overlay :value="searchOverlay" @click="closeSearchOverlay" ></v-overlay>

      <v-menu
        v-bind="props"
        open-on-hover
        open-on-click
        >
        <template v-slot:activator="{ props }">
          <v-btn
            color="#1846a4"
            v-bind="props"
            size="small"
            variant="tonal"
            append-icon="mdi-chevron-down"
            style="text-transform:none !important; color: #1846a4"
          >
            {{ selectedLanguage }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item link v-if="selectedLanguage != 'En'"  @click="changeLang(0)"><v-icon>mdi-chevron-right </v-icon>  En
          </v-list-item>
          <v-list-item link v-if="selectedLanguage != 'ትግ'"  @click="changeLang(1)"><v-icon>mdi-chevron-right </v-icon>  ትግ
          </v-list-item>
          <v-list-item link v-if="selectedLanguage != 'ኣማ'"  @click="changeLang(2)"><v-icon>mdi-chevron-right </v-icon>  ኣማ
          </v-list-item>
        </v-list>
      </v-menu>

  </v-app-bar>
  <v-navigation-drawer v-model="drawerOpen" app v-if="mobile">
      <span v-for="menu in parentMenus">
        <span v-if="getChildren(menu.id).length > 0">
          <v-menu
            transition="slide-x-transition"
            v-if="mobile"
            open-on-hover
            open-on-click
          >
            <template v-slot:activator="{ props, on }">
              <v-btn
                block
                variant="text"
                v-bind="props"
                v-on="on"
                prepend-icon="mdi-chevron-right"
                style="text-transform:none !important; justify-content: flex-start"
              >
                {{ menu.title }}
                <v-spacer></v-spacer>
                <template v-slot:append>
                  <v-icon>mdi-chevron-down</v-icon>
                </template>
              </v-btn>
            </template>
            <v-list :style="`font-family: ${$root.fontFamily}`">
              <v-list-item transparent="1"
                v-for="(submenu, index) in getChildren(menu.id)"
                :to="`${submenu.url}`"
              class="menu-item" v-on:mouseover="isHovered = true" v-on:mouseleave="isHovered = false"> <v-icon>mdi-chevron-right </v-icon> {{ submenu.title }}
              </v-list-item>
            </v-list>
          </v-menu>
        </span>
        <span v-else>
          <v-list-item link :to="`${menu.url}`"  >
          <v-icon > mdi-chevron-right </v-icon> {{ menu.title }}
        </v-list-item>
        </span>
      </span>

  </v-navigation-drawer>
</template>

<script>
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useUserStore } from "@/store/auth";
import calendar from "@/views/calendars/calendar_notification.vue";

export default {
  name: 'NavbarWeb',
  components: {
    calendar,
  },
  setup() {    
    const userStore = useUserStore();        
  
    return {userStore };
  },
  data () {
    return {

      langData : [
        // Amharic
        {
        },
      ], 
      currentLanguageIndex: 0, // Set the index of the desired language

      searchOverlay: false,
      searchQuery: '',    
      menubg: true,

      company: {
        phone: '+2519 3081 2828',
        email: 'info@tigdigital.gov.et',
      },

      allMenuResponse: [],
      allTransMenuResponse: [],
      // allTransMenuResponse: [],
      parentMenus: [],
      childrenMenus: [],

      mobile: null,
      windowWidth: null,
      drawerOpen: false,
      rightDrawerOpen: false,
      socialMediasResponse: [],
      categoriesResponse: [],
      resourceTypes: [],
      showLang: true,
      chngLan: 'Change Language',
      lanIndex: 0,
      i: 0,
      chngLanHint: ['ቋንቛ ይምረፁ', 'ቋንቋ ይቀይሩ', 'Change Language'],
      intervalId: null,

      languages: ['En','ትግ', 'ኣማ' ],
      selectedLanguage: 'En',

      fullscreen: false,  // Whether the app is currently in fullscreen mode
      router: useRouter(),
      isHovered: false,

      visible: true,
      lastScrollTop: 0
      
    }
  },
  mounted () {          
    window.addEventListener("resize",this.checkscreen);
    this.checkscreen();
    this.getSocialMedias();
    this.getCategories();
    this.getResourceTypes();
    this.loadLocalMenu();
    this.getMenu();
    // Start the loop when the component is mounted
    this.startLoop();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.intervalId);
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    currentLangData() {
      // Get the current language array based on the index
      return this.langData[this.currentLanguageIndex];
    },
    telephoneLink() {
      return `tel:${this.company.phone.replace(/\s/g, '')}`;
    },
    emailLink() {
      return `mailto:${this.company.email}`;
    },
  },
  methods: {
    tranMenu(title, m_id){
      if(this.$i18n.locale == 'En')
        return title;
      else if (this.$i18n.locale == 'ትግ'){
         let text = this.allTransMenuResponse.find((item) => item.foreign_key === m_id && item.locale === 'ti');
         return text != null ? text.value : title;
      }
      else if (this.$i18n.locale == 'ኣማ'){
        let text = this.allTransMenuResponse.find((item) => item.foreign_key === m_id && item.locale === 'am');
         return text != null ? text.value : title;
      }
    },
    loadLocalMenu(){
      if(localStorage.getItem('storedMenu') != null) {
        let storedMenu = localStorage.getItem('storedMenu');
        this.allMenuResponse = JSON.parse(storedMenu);
        let storedTransMenu = localStorage.getItem('storedTransMenu');
        this.allTransMenuResponse = JSON.parse(storedTransMenu);
        

        this.parentMenus = this.allMenuResponse.filter(item => item.parent_id === null);
        this.parentMenus = this.parentMenus.sort((a, b) => a.order - b.order);
        this.childrenMenus = this.allMenuResponse.filter(item => item.parent_id != null);
      }
    },
    async getMenu(){ 
      
      await axios.get('/menus/2/builder')
        .then(response => {
          // this.loading = false
          this.allMenuResponse = response.data.menu.items;
          this.allTransMenuResponse = response.data.transMenus;
          localStorage.setItem('storedMenu', JSON.stringify(this.allMenuResponse));
          localStorage.setItem('storedTransMenu', JSON.stringify(this.allTransMenuResponse));

          this.parentMenus = this.allMenuResponse.filter(item => item.parent_id === null);
          this.parentMenus = this.parentMenus.sort((a, b) => a.order - b.order);
          this.childrenMenus = this.allMenuResponse.filter(item => item.parent_id != null);
          
          // console.log("here");
          // console.log(this.allTransMenuResponse);
          // console.log(this.parentMenus);
          // console.log(this.childrenMenus);
        })
        .catch(error => {
          console.log(error)
        });
    },
    getChildren(p_id){
      let mychildren = this.childrenMenus.filter(item => item.parent_id === p_id);
      let size = mychildren.length;
      return mychildren.sort((a, b) => a.order - b.order);
    },
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      this.visible = scrollTop < this.lastScrollTop || scrollTop < 100;
      this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
    },
    startLoop() {
        // Set up a loop with a time interval (e.g., every 2 seconds in this example)
        this.intervalId = setInterval(() => {          
          // Call a function or perform a task in the loop
          this.showLang = !this.showLang;
          if(this.showLang)
            this.chngLan = this.chngLanHint[this.i];
          if(this.i == 0)
            this.i = 1;
          else if (this.i == 1)
            this.i = 2;
          else if(this.i == 2)
            this.i = 0;
          // if (this.i == 2) { this.i = 0; }
        }, 2000); // Adjust the interval time as needed
    },
    showTooltip(){
      // while(true){
        'there';
        setTimeout(() => {
          // After the timeout, set sent to false
          this.showLang = !this.showLang;
          goto: there;
        }, 2000);
      // }
    },
    openSearchOverlay() {
      this.searchOverlay = true;
      // Check if the ref is available before calling focus()
      if (this.$refs.searchField) {
        this.$refs.searchField.focus();
      }
    },
    closeSearchOverlay() {
      this.searchOverlay = false;
      // Optionally, perform search logic with this.searchQuery
    },
    submitSearch() {
      // Perform search logic with this.searchQuery
      console.log('Search submitted:', this.searchQuery);
      // Optionally, close the overlay after submitting
      this.closeSearchOverlay();
      this.router.push("/search/"+this.searchQuery);
      // alert('Search submitted:', this.searchQuery);
    },
    changeLang(index){
      this.selectedLanguage = this.languages[index];
      this.$i18n.locale = this.selectedLanguage;
      localStorage.setItem('language', this.selectedLanguage); // Save language to localStorage
      this.lanIndex = index;
      this.currentLanguageIndex = index;
    },
    initLanguage() {
      const storedLanguage = localStorage.getItem('language');
      if (storedLanguage && this.languages.includes(storedLanguage)) {
        this.selectedLanguage = storedLanguage;
        this.$i18n.locale = storedLanguage;
      } else {
        this.selectedLanguage = 'En'; // Default to 'en' if no language is stored or it's invalid
      }
    },
    getLocalStr(key) {
      // Access the value for the given key from the current language array
      return this.currentLangData[key];
    },
    trans(keyword){
      let index = this.lanIndex;
      alert(this.langData[0][keyword]);
      switch(index){
        case index:
          return this.langData[0][keyword];
        default:
          return "I don't know";
      }
    },
    checkscreen(){
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1060) {
        this.mobile = true;
        return;
      }
      else {
        this.mobile = false;
      } 
    },
    async getSocialMedias(){
      await axios.get('/social-medias')
        .then(response => {
          this.socialMediasResponse = response.data.mydata
          // this.loading = false
        })
        .catch(error => {
          console.log(error)
        });
    },
    async getCategories(){
      await axios.get('/categories')
        .then(response => {
          this.categoriesResponse = response.data.mydata
          // this.loading = false
        })
        .catch(error => {
          console.log(error)
        });
    },
    async getResourceTypes(){
      await axios.get('/resource-types')
        .then(response => {
          this.resourceTypes = response.data.mydata
        })
        .catch(error => {
          console.log(error)
        });
    },
    toggleFullScreen() {
      // If the document is not currently in fullscreen mode, request fullscreen
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      }
      // Otherwise, exit fullscreen mode
      else {
        document.exitFullscreen();
      }
    },

    exitFullScreen() {
      // Exit fullscreen mode
      document.exitFullscreen();
    },
    updateFullScreenState() {
      // Update the fullscreen state based on whether the document is in fullscreen mode
      this.fullscreen = Boolean(document.fullscreenElement);
    },

    async logout() {
      await this.userStore.logout();
      this.router.push("/signin")
    },
    // logout(){
    //   // console.log(this.userStore.user);
    //   localStorage.removeItem('');
    //   this.router.push({ path: '/signin' });
    // },
  },
  // Define lifecycle hooks to automatically update the fullscreen state of the app
  created() {
    this.initLanguage(); // Call initLanguage when the component is created

    // Add an event listener to update the fullscreen state when the app is created
    this.updateFullScreenState();

    // Add an event listener to update the fullscreen state when the document enters or exits fullscreen mode
    document.addEventListener("fullscreenchange", this.updateFullScreenState);
  },
  destroyed() {
    // Remove the event listener for updating the fullscreen state when the app is destroyed
    document.removeEventListener("fullscreenchange", this.updateFullScreenState);
  }
};
</script>

<style>
.menuitem:hover {
  color: red; /* Change this to the desired active color */
  /*background-color: #1846a4;*/
  /*font-family: 'Cambria';*/
}
.menu-item:hover {
  color: white; /* Change this to the desired active color */
  background-color: #1846a4;
  /*font-family: 'Cambria';*/
}

/* Hide top bar */
.v-application {
  padding-top: 0 !important;
}

/* Adjust menu bar padding */
.v-application .v-app-bar.fixed {
  top: 0;
  z-index: 2;
}
</style>
<template>
  <v-row class="justify-center mb-7 mt-12 pt-5">
        <span class="text-h4" style="border-bottom:5px dashed #0D1B34;">{{$t('our_partners')}}</span>
    </v-row>
    <v-row class="justify-center  mb-7 mt-6 mx-5">
       <span class="text-h5 text-center">{{$t('our_partners_desc')}}</span>
    </v-row>
  <v-sheet
    class="mx-auto"
    elevation="0"
  >
    <v-slide-group
      v-model="model"
      class="pa-4"
      center-active
      show-arrows
    >
      <v-slide-group-item
        v-for="n in partnersResponse"
        :key="n"
        v-slot="{ isSelected, toggle }"
      >
        <v-row>
          <v-col cols="12" md="12" class="text-center">
              <v-avatar color="info" size="200" >
                <v-img
                      :src="`${$root.globalImgUrl}${n.logo}`" 
                      class="align-end"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.1)"
                      max-height="200px"
                      cover
                    >
                    </v-img>
              </v-avatar> 
          </v-col>
        
          <v-col cols="12" md="12" class="text-center">
            <v-btn :href="n.url" target="_blank" variant="text" color="#0D1B34" class="mr-4">
              {{ n.name }}
            </v-btn>
          </v-col>
        </v-row>
      </v-slide-group-item>
    </v-slide-group>
    
  </v-sheet>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      mobile: null,
      windowWidth: null,
      model: null,
      dialog: false,
      partnersResponse: [],
    };
  },
  mounted () {          
      window.addEventListener("resize",this.checkscreen);
      this.checkscreen();
      this.getPartners();
    },

  methods: {
    checkscreen(){
        this.windowWidth = window.innerWidth;
        if (this.windowWidth <= 650) {
          this.mobile = true;
          return;
        }
        else {
          this.mobile = false;
        } 
      },
    getPartners(){
      axios
      .get('/partners')
        .then(response => {
          this.partnersResponse = response.data.mydata
          this.loading = false
        })
        .catch(error => {
          console.log(error)
        });
    },
  },
};
</script>

import {createI18n} from 'vue-i18n';
import en from '@/locales/en.json';
import ti from '@/locales/ti.json';
import am from '@/locales/am.json';
const i18n = createI18n({
	locale: 'En',
	messages: {
		En: en,
		ትግ: ti,
		ኣማ: am,
	},
})
export default i18n
 
<template>
  <v-card class="mx-auto" color="grey-lighten-3">
    <v-layout>
      <v-main>
        <v-container fluid>
          <div class="hello">
            <h1>{{ msg }}</h1>
            <h1>Made By Getters</h1>
            <div v-for="user in getUsers" :key="user.id">
              {{ user.id }} {{ user.name }} {{ user.address }}
            </div>
            <h1>Made By Actions</h1>
            <div v-for="user in users" :key="user.id">
              {{ user.id }} {{ user.name }} {{ user.address }}
            </div>
          </div>
        </v-container>
      </v-main>
    </v-layout>
  </v-card>
</template>
  
<script setup>
import { ref, onMounted, computed } from "vue";
import { useUserStore } from "@/stores/users";
const store = useUserStore();
const msg = ref("Welcome to my Pinia Store");
const getUsers = computed(() => {
  return store.getUsers;
});
const users = computed(() => {
  return store.users;
});
onMounted(() => {
  store.fetchUsers();
});
</script>